// HOME MOBILE

//Landing
.landing
  margin-top: 4rem
  margin-bottom: 1rem
  padding: 0 0 5rem 0
  background: $bg-degraded
  border-radius: 0 0 70px 70px
  text-align: center
  &__animation
    div
      width: 100vw
  &__text
    text-align: center
    padding: 0 1rem
    h1
      margin-top: 0
      color: $color-yellow
      font-size: $main-title-mb
    p
      text-align: center
      font-size: $text-mb
      color: $color-gray-text
      padding-bottom: 1rem


// Benefits

.benefits
  &__container
    padding: 3rem 1rem
  &__title-h2
    font-weight: 300
    text-align: center
    font-size: $main-title-mb
    color: $color-gray-text
    margin-bottom: 4rem
  &__card-left
    margin-top: 5rem
    text-align: center
    &--icon
      z-index: 1
      margin-bottom: -1.25rem
      img
        background-color: $color-white
        width: 3.5rem
        box-shadow: 0 0 46px #00000029
        border-radius: 15px
        padding: .25rem
    &--text
      text-align: center
      box-shadow: 0 0 46px #00000029
      padding: 1rem 2rem
      border-radius: 30px
      h2
        font-size: $nd-title-mb
        color: $color-blue
      p
        color: $color-gray-text
        font-size: $text-mb
  &__card-right
    margin-top: 5rem
    text-align: center
    &--icon
      z-index: 1
      margin-bottom: -1.25rem
      img
        background-color: $color-white
        width: 3.5rem
        box-shadow: 0 0 46px #00000029
        border-radius: 15px
        padding: .25rem
    &--text
      text-align: center
      box-shadow: 0 0 46px #00000029
      padding: 1rem 2rem
      border-radius: 30px
      h2
        font-size: $nd-title-mb
        color: $color-blue
      p
        color: $color-gray-text
        font-size: $text-mb
  &__talki
    margin-top: 8rem
    text-align: center
    h2
      font-size: $main-title-mb
      color: $color-yellow
    p
      font-size: $text-mb
      color: $color-gray-text
      padding-bottom: 2rem
    &-button
      img
        margin: 3rem auto 0 auto
        display: block
        width: 8rem

// Freetrial

.freetrial
  margin-top: -5.25rem
  background: $bg-degraded-nd
  border-radius: 70px 70px 0 0
  &__container
    text-align: center
  &__text
    padding: 5rem 2rem 3rem 2rem
    h2
      font-size: $main-title-mb
      color: $color-yellow
    p
      font-size: 1rem
      color: $color-gray-text
      padding-bottom: 3rem
  &__mockup
    margin-top: 3rem
    img
      width: 100%


// Plans

.plans
  box-sizing: border-box
  &__container
    margin: 4rem 1rem
  &__basic
    box-shadow: 0 0 46px #00000029
    margin-top: 7rem
    padding-bottom: 5rem
    padding-top: 1rem
    background-color: $color-gray-box
    border-radius: 30px
    &-title
      padding-bottom: 3rem
      text-align: center
      h3
        color: $color-blue
        font-size: $nd-title-mb
        text-transform: uppercase
      hr
        width: 100%
        height: .25rem
        background-color: $color-blue
        border: none
      p
        font-size: $nd-title-mb
        font-weight: 600
    &-btn
      text-transform: uppercase
      padding-bottom: 3rem
      text-align: center
    &-text
      padding: 0 2rem
      display: grid
      grid-template-columns: 2rem 10rem
      align-items: center
      justify-content: center
      img
        margin: 0 auto
        width: 2rem
      p
        padding-left: 2rem
        font-size: $text-mb
  &__advance
    box-shadow: 0 0 46px #00000029
    margin-top: 5rem
    padding-bottom: 3rem
    padding-top: 1rem
    background-color: $color-gray-box
    border-radius: 30px
    &-title
      padding-bottom: 3rem
      text-align: center
      h3
        color: $color-green-avanzado
        font-size: $nd-title-mb
        text-transform: uppercase
      hr
        width: 100%
        height: .25rem
        background-color: $color-green-avanzado
        border: none
      p
        font-size: $nd-title-mb
        font-weight: 600
    &-btn
      text-transform: uppercase
      padding-bottom: 3rem
      text-align: center
    &-text
      padding: 0 2rem
      display: grid
      grid-template-columns: 2rem 10rem
      align-items: center
      justify-content: center
      img
        width: 2rem
      p
        padding-left: 2rem
        font-size: $text-mb
  &__label
    margin-top: 10rem
    margin-bottom: -1rem
    position: relative
    &-talki
      img
        position: absolute
        left: 24vw
        top: -5.65rem
        width: 10rem
        @media (min-width: 450px)
          left: 40vw
    &-text
      background-color: $color-pink-premium
      border-radius: 30px 30px 0 0
      text-align: center
      color: $color-white
      font-size: 1.25rem
      padding: .5rem 0
  &__premium
    box-shadow: 0 0 46px #00000029
    margin-top: -.5rem
    padding-bottom: 3rem
    padding-top: 1rem
    width: 100%
    background-color: $color-gray-box
    border-radius: 0 0  30px 30px
    &-title
      padding-bottom: 3rem
      text-align: center
      h3
        color: $color-pink-premium
        font-size: $nd-title-mb
        text-transform: uppercase
      hr
        width: 100%
        height: .25rem
        background-color: $color-pink-premium
        border: none
      p
        font-size: $nd-title-mb
        font-weight: 600
    &-btn
      text-transform: uppercase
      padding-bottom: 3rem
      text-align: center
    &-text
      padding: 0 2rem
      display: grid
      grid-template-columns: 2rem 10rem
      align-items: center
      font-size: $text-mb
      justify-content: center
      img
        width: 2rem
      p
        padding-left: 2rem
        font-size: $text-mb
  &__enterprise
    box-shadow: 0 0 46px #00000029
    margin-top: 5rem
    padding-bottom: 3rem
    padding-top: 1rem
    background-color: $color-gray-box
    border-radius: 30px
    &-title
      padding-bottom: 3rem
      text-align: center
      h3
        color: $color-yellow-enterprise
        font-size: $nd-title-mb
        text-transform: uppercase
      hr
        width: 100%
        height: .25rem
        background-color: $color-yellow-enterprise
        border: none
      p
        font-size: $nd-title-mb
        font-weight: 600
    &-btn
      text-transform: uppercase
      padding-bottom: 3rem
      text-align: center
    &-text
      padding: 0 2rem
      display: grid
      grid-template-columns: 2rem 10rem
      align-items: center
      justify-content: center
      img
        width: 2rem
      p
        padding-left: 2rem
        font-size: $text-mb

.disabled
  color: $color-gray-nd
.bold
  font-weight: 600

//LOGOS

.logos
  &__container
    margin: 4rem 1rem
  &__box
    padding: 3rem 2rem
    background-color: $color-gray-box
    border-radius: 30px
    text-align: center
    &-text
      ul
        padding: 0
        text-align: left
        p
          margin: 0
      p
        margin-bottom: 5rem
    &-img
      margin-top: 3rem
      img
        width: 12rem
        height: 100%
        margin: 1rem auto