// BUTTON MOBILE

.btn
  border-radius: 2.5rem
  padding: .75rem 1.75rem
  box-shadow: 0 3px 6px $color-gray-shadow
  &-blue
    border: 1px solid $color-blue
    color: $color-blue
  &-gray
    border: 1px solid $color-gray
    color: $color-gray
