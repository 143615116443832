// HEADER MOBILE

.header
  position: fixed
  top: 0
  right: 0
  left: 0
  z-index: 5000
  font-family: Poppins
  background-color: $color-white
  &__container
    display: flex
    align-items: center
    justify-content: space-between
    padding: $container-menu-mb
    box-shadow: 0 3px 6px $color-gray-shadow
  &__logo
    img
      width: $logo-menu-mb
  &__menu
    img
      width: 2rem
    &-ul
      padding-top: 3rem
      height: 80vh
      margin: 0
      position: fixed
      bottom: 0
      left: 0
      right: 0
      background-color: $color-gray-menu
      justify-content: space-between
      border-radius: 60px 60px 0 0
      @media (min-height: 320px) and (max-height: 567px)
        height: 70vh
        padding-top: 2rem
      li
        a
          color: $color-gray
        p
          @media (min-height: 320px) and (max-height: 567px)
            margin: 0
      hr
        margin: 3rem 0
        width: 20%
        height: .5px
        background-color: $color-gray-text
        border: none
        @media (min-height: 320px) and (max-height: 567px)
          margin: 1rem 0
  &__buttons
    display: none



.menu-open
  transform: translateY(0)
  transition: 1s

.menu-close
  transform: translateY(100vh)
  transition: 1s