// ENTERPRISE MOBILE


// Enterprise
.enterprise
  margin-top: 5rem
  margin-bottom: 5rem
  background: $bg-degraded
  border-radius: 0 0 70px 70px
  text-align: center
  &__container
    padding: 3rem 2rem 0 2rem
  &__flex
    justify-content: center
    display: flex
    flex-direction: column
  &__text
    margin-bottom: 3rem
    h1
      margin: 0 0 3rem 0
      color: $color-yellow
      font-size: $main-title-mb
    p
      text-align: center
      font-size: 1rem
      color: $color-gray-text
      margin-bottom: 4rem
  &__list
    margin: 0 auto
    &-item
      display: flex
      img
        width: 1.5rem
        margin-right: 1rem
  &__img
    text-align: center
    margin-top: 1.5rem
    img
      width: 10rem
      margin-bottom: -0.5rem


// Info

.info
  padding: 3rem 2rem 3rem 2rem
  &__card-left
    margin-bottom: 7rem
    text-align: center
    &--text
      img
        width: 5rem
      h2
        color: $color-blue
        font-size: $main-title-mb
    &--img
      img
        width: 100%
        border-radius: 10px
  &__card-right
    margin-bottom: 7rem
    text-align: center
    display: flex
    flex-direction: column-reverse
    &--text
      img
        width: 4rem
      h2
        color: $color-blue
        font-size: $main-title-mb
    &--img
      img
        width: 100%
        border-radius: 10px

// Solicita

.solicita
  background: $bg-degraded-nd
  border-radius: 70px 70px 0 0
  margin-bottom: -10rem
  &__container
    text-align: center
    padding: 5rem 2rem 10rem 2rem
  &__flex
    display: flex
    flex-direction: column-reverse
    justify-content: center
    &-img
      text-align: center
      img
        width: 10rem
    &-text
      text-align: center
      h2
        font-size: $main-title-mb
        color: $color-yellow