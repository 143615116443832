// PLAN MOBILE

// PLANSPAGE

.plansPage
  padding: 5rem 1rem 3rem 1rem
  background: $bg-degraded
  border-radius: 0 0 70px 70px
  &__text
    text-align: center
    h1
      color: $color-yellow
      font-size: $main-title-mb
    p
      font-size: 1rem
      color: $color-gray-text
      padding-bottom: 3rem

// COMPARA
.responsive-none
  display: none

// FAQ

.faq
  margin-top: 5rem
  &__container
    margin: 5rem 1rem 3rem 1rem
    text-align: center
  &__title
    h2
      color: $color-yellow
      font-size: $main-title-mb
      padding-bottom: 3rem
  &__text
    &-1
      margin-bottom: 4rem
    &-question
      font-weight: 600
      color: $color-gray-text
    &-answer
      color: $color-gray-text


// SUPPORT

.support
  background: $bg-degraded-nd
  border-radius: 70px 70px 0 0
  margin-bottom: -15rem
  &__container
    padding: 2rem 2rem 10rem 2rem
  &__title
    padding-top: 3rem
    text-align: center
    h2
      color: $color-yellow
      font-size: $main-title-mb
      padding-bottom: 2rem
  &__text
    text-align: center
    p
      font-size: 1rem
      color: $color-gray-text
      padding-bottom: 2rem
  &__img
    img
      margin-top: 1rem
      width: 10rem
