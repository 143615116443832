//FOOTER MOBILE

.footer
  margin-top: 10rem
  background-color: $color-gray
  color: $color-gray-light
  border-radius: 0 100px 0 0
  &__container
    padding: $container-mb-footer
  &__links
    ul
      padding: 0
      li
        margin: 0
        a
          color: $color-gray-light
  &__logo
    margin-top: 3rem
    p
      margin-bottom: 0
    &-img
      margin: 1rem auto 0 auto
      text-align: center
      a
        img
          width: 10rem

