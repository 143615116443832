// Platform Mobile

// Platform
.platform
  margin-top: 5rem
  margin-bottom: 3rem
  padding: 0 0 5rem 0
  background: $bg-degraded
  border-radius: 0 0 70px 70px
  &__container
    display: flex
    flex-direction: column-reverse
    margin: 0 1rem
  &__mockup
    img
      width: 100%
  &__text
    text-align: center
    h1
      margin: 2rem 0 3rem 0
      color: $color-yellow
      font-size: $main-title-mb
    p
      font-size: 1rem
      color: $color-gray-text
      margin-bottom: 4rem

// Mockups

.mockups
  &__container
    padding: 3rem 1rem 3rem 1rem
  &__card
    &-left
      margin: 5rem auto
      text-align: center
      &--img
        img
          border-radius: 15px
          width: 100%
      &--icon
        z-index: 1500
        margin-bottom: -2rem
        img
          width: 3.5rem
          box-shadow: 0 0 46px #00000029
          border-radius: 15px
          padding: .25rem
          background-color: $color-white
      &--text
        margin-bottom: 1rem
        box-shadow: 0 0 46px #00000029
        padding: 2rem
        border-radius: 30px
        background-color: $color-white
        h2
          font-size: $main-title-mb
          color: $color-blue
        p
          color: $color-gray-text
          font-size: $text-mb
    &-right
      margin: 5rem auto
      text-align: center
      &--img
        img
          border-radius: 15px
          width: 100%
      &--icon
        z-index: 1500
        margin-bottom: -2rem
        img
          background-color: $color-white
          width: 3.5rem
          box-shadow: 0 0 46px #00000029
          border-radius: 15px
          padding: .25rem
      &--text
        margin-bottom: 1rem
        box-shadow: 0 0 46px #00000029
        padding: 2rem
        border-radius: 30px
        background-color: $color-white
        h2
          color: $color-blue
        p
          color: $color-gray-text
          font-size: $text-mb

// Contacto

.contact
  background: $bg-degraded-nd
  border-radius: 70px 70px 0 0
  margin-bottom: -15rem
  &__container
    text-align: center
    padding: 2rem 2rem 10rem 2rem
  &__text
    h2
      font-size: $main-title-mb
      color: $color-yellow
    p
      font-size: 1rem
      color: $color-gray-text
      margin-bottom: 3rem
  &__img
    img
      margin-top: 2rem
      width: 10rem